import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { Link } from 'gatsby';

import SEO from '../components/Seo';
import Layout from '../components/layout';
import OnboardingWelcome from '../components/onboarding/Onboarding';
import PrivateRoute from '../components/auth/protected';
import background from '../images/background.png';
import 'react-toastify/dist/ReactToastify.css';

import Welcome from '../components/onboarding/Welcome';
import Question from '../components/onboarding/Questions';
import { getAllAnswers } from '../utils/api';
import {
  setAnswer,
  setQuestions,
  setTotalRanking,
  updateAnswer,
  updateQuestion,
} from '../state/create/answer/action';
import { setUpdateQuestion } from '../state/create/answer/thunks';
import { updateUser } from '../state/login/thunks';
import { formatDateNew, pigLatin } from '../utils/helpers';
import HubspotMeet from '../components/onboarding/HubspotMeet';

const onboardingPage = ({
  setAnswersToState,
  stateAnswers,
  setQuestionsToState,
  stateQuestions,
  updateQuestion,
  updateAnswer,
  updateUserAnswers,
  profile,
  setTotalRanking,
  accessToken,
}) => {
  const [isSubmiting, setSubmit] = useState(false);
  const [step, setStep] = useState(0);
  const [showButtons, setShouldShow] = useState(true);
  const [userObject, setUserObject] = useState({ hapi: '0' });
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [validAnswer, setValidAnswer] = useState(false);
  const [questionStep, setQuestionStep] = useState(0);
  const [answers, setAnswers] = useState([]);

  const [written, setWritten] = useState(false);
  const [writtenAnswer, setWrittenAnswer] = useState(null);
  const [writtenQuestion, setWrittenQst] = useState(null);
  const [load, setLoad] = useState(false);

  const getAnswers = async () => {
    const data = await getAllAnswers(accessToken);
    setAnswers(data);
    const ques = [];
    data.map((item) => ques.push(item.question));
    const newQuestions = ques.filter(
      (v, i, a) => a.findIndex((t) => t.id === v.id) === i
    );
    setQuestionsToState(newQuestions);
  };

  useEffect(() => {
    getAnswers();
  }, []);

  let newSteps = [];
  stateQuestions.length > 0 &&
    stateQuestions.forEach((element) => {
      newSteps.push(Question);
    });

  const Steps = [OnboardingWelcome, Welcome];
  let allSteps = [...Steps, ...newSteps, HubspotMeet];
  const getComponent = (num) => allSteps[num];
  const componentRef = useRef();
  const Component = getComponent(step);

  let customerAnswers = '';
  let ranking = 0;
  stateAnswers.map((ans) => {
    answers.map((item) => {
      if (ans.answer === item.id) {
        ranking = ranking + Number(item.ranking);
        customerAnswers =
          customerAnswers +
          `Question: ${item.question.question}; Answer:${item.answer}; Ans-id:${
            item.id
          }; Ranking:${item.ranking}; date:${formatDateNew(
            new Date(),
            'd MMMM yyyy',
            true
          )};===`;
      }
    });
  });

  const handlehub = async (user) => {
    const splitted = user.answers ? user.answers.split('===') : [];
    let newArray = [];
    splitted.map((ans) => {
      const ansSplit = ans.split(';');
      const questionSplit = ansSplit[0] ? ansSplit[0].split(':') : null;
      const answerSplit = ansSplit[1] ? ansSplit[1].split(':') : null;
      newArray.push({
        question:
          questionSplit && questionSplit[1] ? questionSplit[1].trim() : null,
        answer: answerSplit && answerSplit[1] ? answerSplit[1] : null,
      });
    });

    const noNullArray = newArray.filter((it) => it.question !== null);

    const newArrayWithWritten = written
      ? noNullArray.map((it) => {
          if (it.question.includes('How did you discover')) {
            return {
              question: it.question,
              answer: writtenAnswer,
            };
          } else {
            return it;
          }
        })
      : noNullArray;

    const newQuest = newArrayWithWritten.map((it) => {
      const nah = it.question.slice(0, -1);
      const ques = pigLatin(nah.toLowerCase());
      return {
        question:
          ques.includes('what_stage_is_your') ||
          ques.includes('how_did_you_discover_gildform')
            ? ques.slice(0, -1)
            : ques,
        answer: it.answer,
      };
    });

    const normalFields = [
      {
        objectTypeId: '0-1',
        name: 'email',
        value: user.email,
      },
      {
        objectTypeId: '0-1',
        name: 'firstname',
        value: user.firstName,
      },
      {
        objectTypeId: '0-1',
        name: 'lastname',
        value: user.lastName,
      },
    ];

    const newFields = newQuest.map((item) => {
      return {
        objectTypeId: '0-1',
        name: `${item.question}`,
        value: item.answer,
      };
    });
    const allFields = normalFields.concat(newFields);

    const date = new Date();
    var data = {
      submittedAt: `${date.getTime()}`,
      fields: allFields,
      context: {
        // hutk: hutl, // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
        // pageUri: 'https://migration.gildform.com/onboarding',
        pageUri: `${window ? window.location.href : ''}`,
        pageName: 'Onboarding',
      },
      legalConsentOptions: {
        // Include this object when GDPR options are enabled
        consent: {
          consentToProcess: true,
          text:
            'I agree to allow Gildform Company to store and process my personal data.',
          communications: [
            {
              value: true,
              subscriptionTypeId: 999,
              text:
                'I agree to receive marketing communications from Gildform Company.',
            },
          ],
        },
      },
    };

    const res = await fetch(
      `https://api.hsforms.com/submissions/v3/integration/submit/5994191/1141b721-2c5d-409e-9604-e694e3725901`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }
    );
    const dd = await res.json();
  };

  const action = showButtons
    ? () => {
        setStep(step + 1);
        if (step > 1) {
          setQuestionStep(questionStep + 1);
        }
      }
    : async (user) => {
        setLoad(true);
        // const after = querystring('after')[0] || '/loading';
        handlehub(user);
        updateUserAnswers(user);
        setTotalRanking(ranking);
        // navigate(after);
        setStep(step + 1);
        setLoad(false);
      };

  const title = showButtons ? 'Continue' : 'Finish';
  const length = allSteps.length;

  let newObject = [];

  return (
    <PrivateRoute
      forceCreate={false}
      validate={(userID, tok, getUser) => {
        getUser(userID)
          .then(() => {
            // navigate('/');
          })
          .catch((e) => false);
      }}
    >
      <Helmet>
        <script
          type='text/javascript'
          id='hs-script-loader'
          async
          defer
          src='//js.hs-scripts.com/5994191.js'
        />
        <script
          charSet='utf-8'
          src='https://js.hscta.net/cta/current.js'
        ></script>
      </Helmet>
      <Layout
        onCreate
        className={
          step === 0 ? 'onboarding' : step === 1 ? 'welcome' : 'questions'
        }
        onboarding={true}
        backgroundImage={background}
      >
        <SEO title='Onboard' />
        <Formik
          initialValues={{}}
          onSubmit={(values) => {
            const newUser = { ...profile, answers: customerAnswers };

            action(newUser);
            setUserObject(newObject);
          }}
          render={(props) => (
            <Form
              onSubmit={props.handleSubmit}
              validated={props.isValid}
              className='profile-form'
            >
              {step === 0 ||
                (step === 1 || step === length - 1 ? (
                  <div />
                ) : (
                  <div className='stepper-wrapper'>
                    {stateQuestions.map((item, idx) => {
                      return (
                        <div
                          key={idx}
                          className={
                            item.completed
                              ? `stepper-item completed`
                              : `stepper-item`
                          }
                        >
                          <div className={`step-counter`}>{idx + 1}</div>
                        </div>
                      );
                    })}
                  </div>
                ))}
              <Component
                {...props}
                forwardedRef={componentRef}
                profile={userObject}
                goToStep={setStep}
                setSubmit={(isSubmit) =>
                  (newSteps.length === 0 && step === 1) || step === length - 2
                    ? setShouldShow(false)
                    : setShouldShow(!isSubmit)
                }
                newSteps={newSteps.length}
                questions={stateQuestions || []}
                step={step}
                authToken={accessToken}
                selectedAnswers={selectedAnswers}
                setSelectedAnswers={setSelectedAnswers}
                setAnswersToState={setAnswersToState}
                setShouldShow={setShouldShow}
                length={length}
                setValidAnswer={setValidAnswer}
                validAnswer={validAnswer}
                updateQuestion={updateQuestion}
                stateAnswers={stateAnswers}
                updateAnswer={updateAnswer}
                customerAnswers={customerAnswers}
                allAnswers={answers}
                written={written}
                setWritten={setWritten}
                setWrittenAnswer={setWrittenAnswer}
                writtenAnswer={writtenAnswer}
                setWrittenQst={setWrittenQst}
                load={load}
              />
              <Row className='create-form-controls onboarding-buttons'>
                {step === length - 1 ? (
                  <Col>
                    <span
                      className='hs-cta-wrapper'
                      id='hs-cta-wrapper-7e3479e0-a425-41bb-8220-e0083d6ad62a'
                    >
                      <span
                        className='hs-cta-node hs-cta-7e3479e0-a425-41bb-8220-e0083d6ad62a'
                        id='hs-cta-7e3479e0-a425-41bb-8220-e0083d6ad62a'
                      >
                        <div id='hs-cta-ie-element'></div>
                        <a
                          href='https://cta-redirect.hubspot.com/cta/redirect/5994191/7e3479e0-a425-41bb-8220-e0083d6ad62a'
                          target='_self'
                          rel='noopener'
                        >
                          <img
                            className='hs-cta-img'
                            id='hs-cta-img-7e3479e0-a425-41bb-8220-e0083d6ad62a'
                            style={{
                              borderWidth: '0px',
                              marginRight: '10px',
                            }}
                            src='https://no-cache.hubspot.com/cta/default/5994191/7e3479e0-a425-41bb-8220-e0083d6ad62a.png'
                            alt='I&#39;ll Book A Meeting Later'
                          />
                        </a>
                      </span>
                    </span>
                  </Col>
                ) : (
                  <>
                    <Col>
                      {step === 0 ? (
                        <Link
                          to='/our-story'
                          style={{ textDecoration: 'none' }}
                        >
                          <Button className='learnMoreButton'>
                            {'Learn more'}
                          </Button>
                        </Link>
                      ) : null}
                      {step > 0 && (
                        <Button
                          onClick={() => {
                            setStep(step - 1);
                            if (questionStep >= 1) {
                              setQuestionStep(questionStep - 1);
                            }
                            stateQuestions.map((que, idx) => {
                              if (questionStep == idx) {
                                if (que.completed) {
                                  setValidAnswer(true);
                                }
                              }
                            });
                          }}
                          className='backButton'
                        >
                          Back
                        </Button>
                      )}
                    </Col>
                    <Col>
                      <Button
                        type='submit'
                        disabled={
                          step === 0 || step === 1
                            ? isSubmiting
                            : written
                            ? !writtenAnswer
                            : !validAnswer
                        }
                        className='continueButton'
                      >
                        {isSubmiting ? (
                          <>
                            <i className='fas fa-circle-notch fa-spin' />{' '}
                            Submitting
                          </>
                        ) : (
                          title
                        )}
                      </Button>
                    </Col>
                  </>
                )}
              </Row>
            </Form>
          )}
        />
      </Layout>
    </PrivateRoute>
  );
};

const mapStateToProps = (state) => ({
  stateAnswers: state.create.answer.answers,
  stateQuestions: state.create.answer.questions,
  profile: state.login.auth && state.login.auth.profile,
  accessToken: state.login.auth && state.login.auth.auth.accessToken,
});

const mapDispatchToProps = (dispatch) => ({
  setAnswersToState: (data) => dispatch(setAnswer(data)),
  setQuestionsToState: (data) => dispatch(setQuestions(data)),
  updateQuestion: (data) => dispatch(setUpdateQuestion(data)),
  updateQuestionAction: (data) => dispatch(updateQuestion(data)),
  updateAnswer: (data) => dispatch(updateAnswer(data)),
  updateUserAnswers: (user) => dispatch(updateUser(user)),
  setTotalRanking: (data) => dispatch(setTotalRanking(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(onboardingPage);
