import React, { useEffect } from 'react';
import gildformText from '../../images/gildform-text.png';

export default ({ setSubmit, hasView }) => {
  useEffect(() => {
    setSubmit(false);
    return () => {
      setSubmit(true);
    };
  }, [hasView]);

  return (
    <div>
      <img className='gildformtext-logo' src={gildformText} />
      <h1 className='quote1'>Your dream brand starts here.</h1>
      <p className='firstStepParagraph'>
        On-demand jewerly design and manufacturing - create your life.
      </p>
    </div>
  );
};
