import {
  createRoadmapActivity,
  getAnswer,
  safeGetAuthFromState,
} from '../../../utils/api';
import { setUserChecklist, updateQuestion } from './action';

export const setUpdateQuestion = (value) => async (dispatch, getState) => {
  const authToken = safeGetAuthFromState(getState());
  const data = await getAnswer(Number(value.value), authToken);
  dispatch(
    updateQuestion({ question: data.question, completed: value.completed })
  );
};

export const changeCurrentMilestone = (data, questions) => async (
  dispatch,
  getState
) => {
  const authToken = getState().login.auth.auth.accessToken;
  const bb = await createRoadmapActivity({ data, questions }, authToken);

  dispatch(setUserChecklist(questions));
  // dispatch(updateCurrentMilestone(questions));
};
