import React, { useEffect, useState } from 'react';
import { Form, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import Collector from './index';
import '../../scss/_onboarding.scss';
import { getAnswersByQuestion } from '../../utils/api';

export default ({
  setSubmit,
  questions,
  step,
  authToken,
  setSelectedAnswers,
  selectedAnswers,
  setAnswersToState,
  length,
  setValidAnswer,
  validAnswer,
  updateQuestion,
  stateAnswers,
  updateAnswer,
  allAnswers,
  written,
  setWritten,
  setWrittenAnswer,
  writtenAnswer,
  setWrittenQst,
}) => {
  const [answers, setAnswers] = useState([]);
  const [chosenAnswer, setAnswer] = useState(null);

  useEffect(() => {
    setSubmit(false);
    if (step == length - 1) {
      setSubmit(true);
    }
  }, [step]);

  const getAnswers = async () => {
    const data = await getAnswersByQuestion(questions[step - 2].id, authToken);
    setAnswers(data);
  };

  useEffect(() => {
    getAnswers();
  }, [questions[step - 2].id]);

  useEffect(() => {
    if (validAnswer) {
      setValidAnswer(false);
    }
  }, [step]);

  return (
    <>
      {/* <Collector title=''> */}
      <Form.Group>
        <div className='questionBox'>
          <p className='question1'>{questions[step - 2].question}</p>
          <div className='desc-box'>{questions[step - 2].description}</div>
          <div className='answerBox'>
            <ToggleButtonGroup
              type='radio'
              className='btn-group-separate'
              name='answer'
              value={
                stateAnswers[step - 2]
                  ? stateAnswers[step - 2].answer
                  : chosenAnswer
              }
              onChange={(value) => {
                setAnswer(value);
                if (questions[questions.length - 1]) {
                  if (value === 92) {
                    setWritten(true);
                    setWrittenQst(value);
                  } else {
                    setWritten(false);
                  }
                }
                stateAnswers.map((ans) => {
                  if (ans.question === questions[step - 2].id) {
                    if (ans.answer !== value) {
                      updateAnswer({
                        answer: value,
                        question: questions[step - 2].id,
                        step: step - 2,
                      });
                    }
                  }
                });
                setAnswersToState({
                  question: questions[step - 2].id,
                  answer: value,
                  step: step - 2,
                });
                updateQuestion({ value: value, completed: true });
                if (!validAnswer) {
                  setValidAnswer(true);
                }
              }}
            >
              {answers.length > 0 &&
                answers.map((answer) => {
                  return (
                    <ToggleButton
                      className={
                        (
                          stateAnswers[step - 2]
                            ? answer.id === stateAnswers[step - 2].answer
                            : answer.id === chosenAnswer
                        )
                          ? 'answerButton chosen'
                          : 'answerButton'
                      }
                      type='radio'
                      variant='outline-dark'
                      key={answer.id}
                      value={answer.id}
                    >
                      {answer.answer}
                    </ToggleButton>
                  );
                })}
            </ToggleButtonGroup>
            {written && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  width: '100%',
                  fontSize: '18px',
                  marginTop: '1rem',
                }}
              >
                <Form.Group style={{ width: '20rem' }}>
                  <Form.Label style={{ color: 'white' }}>
                    Please specify an answer:
                  </Form.Label>
                  <Form.Control
                    as='textarea'
                    rows='4'
                    name='written'
                    value={writtenAnswer ? writtenAnswer : ''}
                    onChange={(e) => {
                      setWrittenAnswer(e.target.value);
                    }}
                    placeholder='Write your answer...'
                  />
                </Form.Group>
              </div>
            )}
          </div>
        </div>
      </Form.Group>
      {/* </Collector> */}
    </>
  );
};
