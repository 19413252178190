import React from 'react';
import { findQuestionAndAns } from '../../utils/helpers';

const HubspotMeet = (props) => {
  const showHbsMeets = () => {
    const firstCnd1stMeet = findQuestionAndAns(props.stateAnswers, 68, 8);
    const secondCnd1stMeet = findQuestionAndAns(props.stateAnswers, 74, 24);

    const firstCnd2ndMeet = findQuestionAndAns(props.stateAnswers, 68, 7);

    const firstCnd3rdMeet = findQuestionAndAns(props.stateAnswers, 75, 26);
    const secondCnd3rdMeet = findQuestionAndAns(props.stateAnswers, 75, 27);
    const thirdCnd3rdMeet = findQuestionAndAns(props.stateAnswers, 76, 29);

    const showThirdMeet =
      (firstCnd3rdMeet || secondCnd3rdMeet) && thirdCnd3rdMeet;

    return firstCnd1stMeet && secondCnd1stMeet ? (
      <div className='hbs-meet-main'>
        <div className='hbs-title'>
          White Glove Consultation for Creating A Project (Design)
        </div>
        <div className='hbs-meet-row'>
          <div className='hbs-meet-desc'>
            Create A Design Project with A Gildform Guide on this free white
            glove setup for your first design project. This meeting features a
            personalized experience where you will create your very first Design
            Project on Gildform. We will guide you through the entire Design
            process while answering your questions about bringing your vision to
            life.
            <br />
            Be sure to have the following ready:
            <ul>
              <li>Computer with a stable internet connection</li>
              <li>Your Sketch and/or reference image for your first design</li>
              <li>The dimension of your first design</li>
            </ul>
          </div>
          {props.load ? (
            <i className='fas fa-circle-notch fa-spin' />
          ) : (
            <iframe
              title={'hubspot'}
              className='iframeHbs'
              src={
                'https://try.gildform.com/meetings/hello583/create-a-design-project-with-a-gildform-guide?embed=true'
              }
            />
          )}
        </div>
      </div>
    ) : firstCnd2ndMeet ? (
      <div className='hbs-meet-main'>
        <div className='hbs-title'>
          White Glove On-Demand Production Meeting
        </div>
        <div className='hbs-meet-row'>
          <div className='hbs-meet-desc'>
            Create A Production Order with A Gildform Guide on this free white
            glove setup for your Production Order. This meeting features a
            personalized experience where you will create your very first
            on-demand production order on Gildform. We will guide you through
            the entire On-Demand Production process while answering your
            questions about bringing your vision to life.
            <br />
            Be sure to have the following ready:
            <ul>
              <li>Computer with a stable internet connection</li>
              <li>Your production-ready 3D Models</li>
              <li>Ideas for desired material, polish and assembly</li>
            </ul>
            <br /> *make sure that your files are accessible on your computer.
            <br />
            We're looking forward to empowering you by bringing your ideas to
            life!
          </div>
          {props.load ? (
            <i className='fas fa-circle-notch fa-spin' />
          ) : (
            <iframe
              title={'hubspot'}
              className='iframeHbs'
              src={
                'https://meetings.hubspot.com/aanuoluwapo/white-glove-on-demand-production-meeting?embed=true'
              }
            />
          )}
        </div>
      </div>
    ) : showThirdMeet ? (
      <div className='hbs-meet-main'>
        <div className='hbs-title'>White Glove Shopify App Meeting</div>
        <div className='hbs-meet-row'>
          <div className='hbs-meet-desc'>
            Add Your First Product to Shopify with Your Gildform Guide Add Your
            First Product to Shopify with Your Gildform Guide on this free white
            glove setup for your publishing products to your Shopify Store. This
            meeting features a personalized experience where you will create
            your very first on-demand production order on Gildform. We will
            guide you through the entire Gildform Print On-Demand Production
            process while answering your questions about bringing your vision to
            life. <br />
            Be sure to have the following ready:
            <ul>
              <li>Computer with a stable internet connection</li>
              <li>Your production-ready 3D Models</li>
              <li>Ideas for desired material, polish and assembly</li>
              <li>
                Your Shopify Store login (If you're still setting up your store,
                that's okay, too!)
              </li>
              <li>
                Be prepared to upgrade to Gildform Plus, if you haven't already
              </li>
            </ul>
            <br />
            *make sure that your files are accessible on your computer.
            <br /> We're looking forward to empowering you by bringing your
            ideas to life!
          </div>
          {props.load ? (
            <i className='fas fa-circle-notch fa-spin' />
          ) : (
            <iframe
              title={'hubspot'}
              className='iframeHbs'
              src={
                'https://meetings.hubspot.com/aanuoluwapo/white-glove-shopify-app-meeting?embed=true'
              }
            />
          )}
        </div>
      </div>
    ) : (
      <>
        <div className='hbs-meet-main'>
          <div className='hbs-title'>
            General Gildform Overview Consultation
          </div>
          <div className='hbs-meet-row'>
            <div className='hbs-meet-desc'>
              Ready to bring your jewelry ideas to life, and want to learn more?
              During this complimentary Consultation with Your Gildform Guide we
              will have a general overview of Gildform and learn more about your
              needs.
            </div>
            {props.load ? (
              <i className='fas fa-circle-notch fa-spin' />
            ) : (
              <iframe
                title={'hubspot'}
                className='iframeHbs'
                src={
                  'https://meetings.hubspot.com/aanuoluwapo/complimentary-consultation-with-your-gildform-guide?embed=true'
                }
              />
            )}
          </div>
        </div>
      </>
    );
  };

  return showHbsMeets();
};

export default HubspotMeet;
