import React, { useEffect } from 'react';
import gildformLogo from '../../images/gildformlogo-black.png';

export default ({ setSubmit, hasView }) => {
  useEffect(() => {
    setSubmit(false);
    return () => {
      setSubmit(true);
    };
  }, [hasView]);

  return (
    <div>
      <div className='gildformLogo'>
        <img src={gildformLogo} />
        <h1 className='quote3'>Great!</h1>
      </div>
      <hr />
      <h1 className='quote4'>We're now going to ask you a few questions!</h1>
    </div>
  );
};
