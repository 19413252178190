import {
  SET_ANSWER,
  SET_QUESTIONS,
  COMPLETE_QUES,
  UPDATE_ANSWER,
  SET_TOTAL_RANKING,
  SET_CUSTOMER_ROADMAP,
  SET_CURRENT_MILESTONE,
  UPDATE_CURRENT_MILESTONE,
  SET_USER_CHECKLIST,
  UPDATE_ROADMAP,
} from '../../actionTypes';

export const setAnswer = (payload) => ({
  type: SET_ANSWER,
  payload,
});

export const setQuestions = (payload) => ({
  type: SET_QUESTIONS,
  payload,
});

export const updateQuestion = (payload) => ({
  type: COMPLETE_QUES,
  payload,
});

export const updateAnswer = (payload) => ({
  type: UPDATE_ANSWER,
  payload,
});

export const setTotalRanking = (payload) => ({
  type: SET_TOTAL_RANKING,
  payload,
});

export const setCurrentMilestone = (payload) => ({
  type: SET_CURRENT_MILESTONE,
  payload,
});


export const setCustomerRoadmap = (payload) => ({
  type: SET_CUSTOMER_ROADMAP,
  payload,
});

export const updateCurrentMilestone = (payload) => ({
  type: UPDATE_CURRENT_MILESTONE,
  payload,
});

export const setUserChecklist = (payload) => ({
  type: SET_USER_CHECKLIST,
  payload,
});

export const udpateCurrentRoadmap = (payload) => ({
  type: UPDATE_ROADMAP,
  payload,
});
